body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.3vw;
  line-height: 1.3em;
}

@media (max-width: 768px) {
  body {
    font-size: 2.2vw;
  }
}

@media (max-width: 600px) {
  body {
    font-size: 3vw;
  }
}
