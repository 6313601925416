.pagination {
  margin: 5vw;
  display: flex;
  flex-direction: row;
  gap: 3px;
  position: relative;
  bottom: 0px;
}

.enabled {
  height: 5vw;
  width: 5vw;
  border-radius: 40%;
  border: none;
  background-color: #e1567c;
  cursor: pointer;
  line-height: 0;
}

.disabled {
  cursor: default;
  background-color: #de738f;
  height: 5vw;
  width: 5vw;
  border-radius: 40%;
  border: none;
  line-height: 0;
}

.enabled:hover {
  background-color: #ab4963;
}

.pagination button img {
  height: 40%;
  width: 50%;
}

@media (max-width: 768px) {
  .pagination button {
    width: 10vw;
    height: 10vw;
  }
}

@media (max-width: 600px) {
  .pagination button {
    width: 15vw;
    height: 15vw;
  }
}
