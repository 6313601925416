:root {
  --dark-blurple: #47427e;
}

#toast {
  visibility: hidden;
  min-width: 250px;
  background-color: var(--dark-blurple);
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 1.3vw;
  position: fixed;
  z-index: 1;
  left: 5%;
  bottom: 30px;
  font-size: 1.2vw;
}

@media (max-width: 768px) {
  #toast {
    font-size: 3vw;
    padding: 2.5vw;
  }
}

#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
