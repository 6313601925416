.like-button:hover {
  border-radius: 50%;
  opacity: 1;
  line-height: 0;
  cursor: pointer;
}

.like-button {
  text-shadow: 0 0 6px rgb(192, 44, 94);
}

.hovered {
  opacity: 1;
}

.dark {
  color: #9f2226;
}

.light {
  color: #fddbdc;
}
