.main-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5vw;
  margin: 16px;
  font-family: "Inter", sans-serif;
}

.quotes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4vw;
  margin: 20px;
}

@media (max-width: 768px) {
  .quotes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 7vw;
  }
}

@media (max-width: 600px) {
  .quotes {
    display: grid;
    grid-template-columns: 1fr;
    gap: 7vw;
  }
}

.sticky {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  position: fixed;
  bottom: 25px;
  right: 25px;
}

.icon-button {
  width: 5vw;
  height: 5vw;
  border: none;
  border-radius: 50%;
  background-color: #e1567c;
  opacity: 80%;
  line-height: 0;
  cursor: pointer;
}

@media (max-width: 768px) {
  .icon-button {
    width: 10vw;
    height: 10vw;
  }
}

@media (max-width: 600px) {
  .icon-button {
    width: 15vw;
    height: 15vw;
  }
}

.icon-button img {
  height: 80%;
  width: 80%;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(54deg) brightness(106%)
    contrast(101%);
}

@media (min-width: 1220px) {
  .icon-button:hover img {
    opacity: 0;
    transition: opacity 0.1s ease-out;
    -moz-transition: opacity 0.1s ease-out;
    -webkit-transition: opacity 0.1s ease-out;
  }

  .icon-button .tooltip-text {
    opacity: 0;
    background-color: transparent;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    padding: 0;
    line-height: 4vw;
    font-size: 1vw;
    position: absolute;
    z-index: 1;
  }

  .icon-button:hover .tooltip-text {
    opacity: 1;
    transition: opacity 0.2s ease-in;
    -moz-transition: opacity 0.2s ease-in;
    -webkit-transition: opacity 0.2s ease-in;
  }
}

.icon-button .tooltip-text {
  opacity: 0;
}

#loading {
  color: white;
}
