:root {
  --purple: #8d5494;
  --light-purple: #ad6caa;
  --pink: #fa7faa;
  --dark-pink: #e1567c;
}

.darken-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  transition: 500ms;
}

.close-button:hover {
  opacity: 50%;
  transition: 500ms;
}

.close-button img {
  height: 10px;
  width: 10px;
}

.expanded {
  width: 40vw;
  cursor: default;
}

.expanded:hover {
  box-shadow: none;
}
