:root {
  --purple: #8d5494;
  --light-purple: #ad6caa;
  --pink: #fa7faa;
  --dark-pink: #e1567c;
}

.darken-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  position: relative;
  background: white;
  height: auto;
  width: 50%;
  margin: auto;
  padding: 2% 4%;
  border-radius: 10px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: var(--light-purple);
  transition: 500ms;
}

.close-button:hover {
  color: var(--purple);
  transition: 500ms;
}

.close-button img {
  height: 10px;
  width: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  width: 100%;
  text-align: right;
}

.input-wrapper span {
  font-size: 0.8em;
}

.error {
  color: var(--dark-pink);
  text-align: left;
}

.quote-input {
  height: 7.5em;
  line-height: 1.5;
  resize: none;
  font-family: "Inter", sans-serif;
  font-size: large;
}

.input {
  padding: 10px;
  font-size: large;
}

.input:focus {
  outline: none !important;
  border: 2px solid var(--light-purple);
}

.submit-wrapper {
  display: flex;
  justify-content: flex-end;
}

.submit {
  width: auto;
  background: var(--pink);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 1em 3em;
  cursor: pointer;
  font-weight: bold;
  font-size: 1em;
  text-transform: uppercase;
  margin: 10px 0;
  transition: 500ms;
}

.submit:hover {
  background: var(--dark-pink);
  transition: 500ms;
}
