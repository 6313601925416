:root {
    --blurple: #6A5FC1;
    --dark-blurple: #4D3F9B;
}

.page-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
}

.logo {
   max-width: 15%;
}

.sign-in {
    width: auto;
    background: var(--blurple);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 1em 3em;
    cursor: pointer;
    font-weight: bold;
    font-size: 1em;
    text-transform: uppercase;
    margin: 10px 0;
    transition: 500ms;
}

.sign-in:hover {
    background: var(--dark-blurple);
    transition: 500ms;
}
