:root {
  --v-light-purple: #E2ABE070;
  --light-purple: #AD6CAA;
}

.dropdown {
  box-shadow: none !important;
  border: 1px var(--light-purple) solid;
  border-radius: 30px;
  font-size: inherit;
  padding: 0 5px 0 5px;
  background: none;
}

select {
  border-style: inset;
  outline-color: var(--light-purple);
  font-family: "Inter", sans-serif;
}

.dropdown-wrapper {
  border-radius: 30px;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: var(--v-light-purple);
  border: transparent;
}
