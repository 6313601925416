:root {
  --light-light-purple: #d8cfde;
}

.speech-bubble {
  position: relative;
  border-radius: 1em;
  padding: 3vw;
  width: 20vw;
  height: 12vw;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  cursor: pointer;
}

.speech-bubble:hover {
  box-shadow: 10px 10px 3px 3px var(--light-light-purple);
}

@media (max-width: 768px) {
  .speech-bubble {
    height: 22vw;
    width: 35vw;
  }
}

@media (max-width: 600px) {
  .speech-bubble {
    height: 25vw;
    padding: 30px;
    width: 60vw;
  }
}

.speech-bubble:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  border-left: 0;
  margin-left: -10px;
  margin-bottom: -20px;
}

.side-by-side {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
